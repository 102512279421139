import getCustomPage from 'api/queries/getCustomPage'
import getMenus from 'api/queries/getMenus'
import getFooter from 'api/queries/getFooter'
import getQuickAccess from 'api/queries/quickAccess/getQuickAccess'
import updateQuickAccess from 'api/queries/quickAccess/updateQuickAccess'
import addQuickAccess from 'api/queries/quickAccess/addQuickAccess'
import deleteQuickAccess from 'api/queries/quickAccess/deleteQuickAccess'
import getSearch from 'api/queries/getSearch'
import { getPlansBySegment, getPlansByCategory, getPlansFilters } from 'api/queries/getPlan'
import getUserData from 'api/queries/atos/getUserData'
import getBenefitEligibility from 'api/queries/benefitEligibility/getBenefitEligibility'
import getBillingReport from 'api/queries/billingReport/getBillingReport'
import getBillingReportFinancialResponsible from 'api/queries/billingReportFinancialResponsible/getBillingReportFinancialResponsible'
import getCodeCBO from 'api/queries/codeCBO/getCodeCBO'
import getEmployeeCards from 'api/queries/employeeCards/getEmployeeCards'
import getFaq from 'api/queries/getFaq'
import getCities from 'api/queries/medicalSchedule/getCities'
import getSpecialties from 'api/queries/medicalSchedule/getSpecialties'
import getMedicalSchedule from 'api/queries/medicalSchedule/getMedicalSchedule'
import getOutpatientAuthorizationConsultation from 'api/queries/outpatientAuthorizationConsultation/getOutpatientAuthorizationConsultation'
import getOutpatientAuthorizationsProviderResult from 'api/queries/outpatientAuthorizationConsultation/getOutpatientAuthorizationsProviderResult'
import demonstrativoPagamento from 'api/queries/paymentStatement/demonstrativoPagamento'
import monthlyReport from 'api/queries/paymentStatement/monthlyReport'
import copan from 'api/queries/paymentStatement/copan'
import getReportLogs from 'api/queries/paymentStatement/getReportLogs'
import getRecipientDetails from 'api/queries/PinSS/getRecipientDetails'
import getRecipientsList from 'api/queries/PinSS/getRecipientsList'
import getPinSSUtilization from 'api/queries/PinSS/getPinSSUtilization'
import getRecipientsDate from 'api/queries/PinSS/getRecipientsDate'
import getRecipients from 'api/queries/publicCivilAction/getRecipients'
import updateRecipient from 'api/queries/publicCivilAction/updateRecipient'
import getReadjustmentStatement from 'api/queries/readjustmentStatement/getReadjustmentStatement'
import getRecipientRegister from 'api/queries/registrationUpdate/getRecipientData'
import updateRecipientRegister from 'api/queries/registrationUpdate/updateRecipient'
import getZipcodeList from 'api/queries/unlockCard/getZipCodeList'
import getReferencedList from 'api/queries/referencedList'
import getTriggerEvents from 'api/queries/triggerEvents'
import approveReferencedList from 'api/queries/referencedList/approveReferencedList'
import getCards from 'api/queries/unlockCard/getCards'
import getCardRecipient from 'api/queries/unlockCard/getCardRecipient'
import updateCardRecipient from 'api/queries/unlockCard/updateCardRecipient'
import getWebformSubmissionsQuery from 'api/queries/getWebformSubmissions'
import { getClient, getClientCounterReference, getProvider } from 'api/queries/webforms'
import {
  getToken,
  checkUserAndAddProfile,
  getUser,
  getIpAddress,
  addUser,
  resetPassword,
  rememberPassword,
  rememberEmail,
  activateUser,
  getUserProfiles,
  setUserProfile,
  setUserDefaultProfile,
  grantAccessUser,
  removeAccessUser,
  removeSharedProfile,
  somethingWrong,
  validateActivationToken,
  sendActivationLink,
} from 'api/queries/user'
import {
  getCooperatedDeclaration,
  getCooperatedBankDeclaration,
  getCooperatedRelationshipDeclaration,
  getCooperatedJubilatedDeclaration,
  getCooperatedPublicExamDeclaration,
  getCooperatedRetiredDeclaration,
  getRecipientPortabilityDeclaration,
} from 'api/queries/declarations'

export default {
  menu: {
    query: getMenus,
    method: 'get',
  },
  footer: {
    query: getFooter,
    method: 'get',
  },
  accessmenu: {
    query: getMenus,
    method: 'get',
  },
  'cache-disable/quickaccess': {
    query: getQuickAccess,
    method: 'get',
  },
  add_quickaccess: {
    query: addQuickAccess,
    method: 'post',
  },
  update_quickaccess: {
    query: updateQuickAccess,
    method: 'post',
  },
  delete_quickaccess: {
    query: deleteQuickAccess,
    method: 'post',
  },
  search: {
    query: getSearch,
    method: 'post',
  },
  plan: {
    query: getPlansByCategory,
    method: 'post',
  },
  plan_segment: {
    query: getPlansBySegment,
    method: 'post',
  },
  planfilters: {
    query: getPlansFilters,
    method: 'post',
  },
  atos: {
    query: getUserData,
    method: 'post',
  },
  benefit_elegibility: {
    query: getBenefitEligibility,
    method: 'get',
  },
  billing_report: {
    query: getBillingReport,
    method: 'get',
  },
  billing_report_financial_responsible: {
    query: getBillingReportFinancialResponsible,
    method: 'get',
  },
  cbo: {
    query: getCodeCBO,
    method: 'get',
  },
  employee_cards: {
    query: getEmployeeCards,
    method: 'get',
  },
  faq: {
    query: getFaq,
    method: 'get',
  },
  cities: {
    query: getCities,
    method: 'get',
  },
  specialties: {
    query: getSpecialties,
    method: 'get',
  },
  medical_schedule: {
    query: getMedicalSchedule,
    method: 'post',
  },
  authorization: {
    query: getOutpatientAuthorizationConsultation,
    method: 'get',
  },
  authorization_provider: {
    query: getOutpatientAuthorizationsProviderResult,
    method: 'get',
  },
  'cache-disable/payment_monthly_report': {
    query: monthlyReport,
    method: 'get',
  },
  'cache-disable/payment_report': {
    query: demonstrativoPagamento,
    method: 'get',
  },
  'cache-disable/copan': {
    query: copan,
    method: 'get',
  },
  'cache-disable/get_report_logs': {
    query: getReportLogs,
    method: 'get',
  },
  recipient_details: {
    query: getRecipientDetails,
    method: 'post',
  },
  recipient_list: {
    query: getRecipientsList,
    method: 'post',
  },
  recipient_date: {
    query: getRecipientsDate,
    method: 'post',
  },
  pinss: {
    query: getPinSSUtilization,
    method: 'post',
  },
  recipients: {
    query: getRecipients,
    method: 'post',
  },
  update_recipient: {
    query: updateRecipient,
    method: 'post',
  },
  readjustment_statement: {
    query: getReadjustmentStatement,
    method: 'get',
  },
  recipient_register: {
    query: getRecipientRegister,
    method: 'post',
  },
  update_recipient_register: {
    query: updateRecipientRegister,
    method: 'post',
  },
  zipcode_list: {
    query: getZipcodeList,
    method: 'get',
  },
  'cache-disable/referenced_list': {
    query: getReferencedList,
    method: 'get',
  },
  'cache-disable/trigger_events': {
    query: getTriggerEvents,
    method: 'get',
  },
  'cache-disable/approve_referenced_list': {
    query: approveReferencedList,
    method: 'post',
  },
  cards: {
    query: getCards,
    method: 'get',
  },
  card_recipient: {
    query: getCardRecipient,
    method: 'post',
  },
  update_card_recipient: {
    query: updateCardRecipient,
    method: 'post',
  },
  webform_submission: {
    query: getWebformSubmissionsQuery,
    method: 'post',
  },
  'cache-disable/get_ip': {
    query: null,
    method: 'get',
  },
  get_user: {
    query: getUser,
    method: 'post',
  },
  get_user_profiles: {
    query: getUserProfiles,
    method: 'post',
  },
  set_user_profile: {
    query: setUserProfile,
    method: 'post',
  },
  grant_access_user: {
    query: grantAccessUser,
    method: 'get',
  },
  remove_access_user: {
    query: removeAccessUser,
    method: 'get',
  },
  set_user_default_profile: {
    query: setUserDefaultProfile,
    method: 'get',
  },
  add_user: {
    query: addUser,
    method: 'post',
  },
  reset_password: {
    query: resetPassword,
    method: 'post',
  },
  remember_password: {
    query: rememberPassword,
    method: 'post',
  },
  rememberEmail: {
    query: rememberEmail,
    method: 'post',
  },
  check_user_and_add_profile: {
    query: checkUserAndAddProfile,
    method: 'post',
  },
  activate_user: {
    query: activateUser,
    method: 'post',
  },
  remove_shared_profile: {
    query: removeSharedProfile,
    method: 'post',
  },
  something_wrong: {
    query: somethingWrong,
    method: 'post',
  },
  validate_activation_token: {
    query: validateActivationToken,
    method: 'post',
  },
  send_activation_link: {
    query: sendActivationLink,
    method: 'post',
  },
  get_token: {
    query: getToken,
    method: 'post',
  },
  get_token_nocaptcha: {
    query: getToken,
    method: 'post',
  },
  page: {
    query: getCustomPage,
    method: 'get',
  },
  webforms_client: {
    query: getClient,
    method: 'get',
  },
  webforms_client_counter_reference: {
    query: getClientCounterReference,
    method: 'get',
  },
  webforms_provider: {
    query: getProvider,
    method: 'get',
  },
  'cache-disable/declaration_cooperated': {
    query: getCooperatedDeclaration,
    method: 'get',
  },
  'cache-disable/declaration_cooperated_bank': {
    query: getCooperatedBankDeclaration,
    method: 'get',
  },
  'cache-disable/declaration_cooperated_relationship': {
    query: getCooperatedRelationshipDeclaration,
    method: 'get',
  },
  'cache-disable/declaration_cooperated_jubilated': {
    query: getCooperatedJubilatedDeclaration,
    method: 'get',
  },
  'cache-disable/declaration_cooperated_public_exam': {
    query: getCooperatedPublicExamDeclaration,
    method: 'get',
  },
  'cache-disable/declaration_cooperated_retired': {
    query: getCooperatedRetiredDeclaration,
    method: 'get',
  },
  'cache-disable/declaration_recipient_portability': {
    query: getRecipientPortabilityDeclaration,
    method: 'get',
  },
}
